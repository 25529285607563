import * as jQuery from 'jquery';
//import * as lodash from 'lodash';

declare global {
    interface Window {
        $: typeof jQuery;
        jQuery: typeof jQuery;
        JQuery: typeof jQuery;
//        _: typeof lodash;
    }
}

window.$ = window.JQuery = window.jQuery = jQuery;
//window._ = lodash;
import "@popperjs/core"
import "bootstrap"
import 'lightbox2/dist/js/lightbox.min.js';
